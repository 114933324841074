import type { App } from 'vue';
import MQTreeMenu from './MQTreeMenu';
// import  from ''

// 所有组件列表
const components = [
    MQTreeMenu
]


// // const install = (app: App): void => {
// //     components.map(component => app.component(component.name, component))
// // }

const setupMQUI  = function (app: App<Element>) {
    app.use(MQTreeMenu);
}

export default setupMQUI;