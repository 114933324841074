<template>
  <!-- <nav> -->
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  <!-- </nav> -->
  <router-view />
</template>

<style lang="scss">
body {
    margin: 0 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
//   color: #2c3e50;
  background-color: #f2f5fa;
}

@font-face {
  font-family: "LibreBaskervilleRegular";
  src: url("@/assets/ttf/Libre_Baskerville/LibreBaskerville-Regular.ttf"); //你的资源目录
  font-weight: normal;
  font-style: normal;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
// html {
//   filter: grayscale(100%);

//   -webkit-filter: grayscale(100%); /* webkit */

//   -moz-filter: grayscale(100%); /*firefox*/

//   -ms-filter: grayscale(100%); /*ie9*/

//   -o-filter: grayscale(100%); /*opera*/

//   filter: url("data:image/svg+xml;utf8,#grayscale");

//   filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);

//   -webkit-filter: grayscale(1);
// }
</style>
