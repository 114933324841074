import  './tailwind/index.css';
import "@/assets/icon/iconfont.css"
import type { App } from 'vue';
import setupMQUI from '@/libs/mqtech-ui';


const setupUI  = function (app: App<Element>) {
    setupMQUI(app);
}

export default setupUI;