import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { routes } from "./routerList";




const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    // scrollBehavior: (to, from, savedPosition) => {
    //     return { x: 0, y: 0 };
    //   }
});

router.beforeEach(async (to, from, next) => {
    // NProgress.start();
    // const nprogressColor = store.state.theme.nprogressColor;
    // const dom = document.getElementById("nprogress").childNodes[0];
    // dom.style.setProperty("background", nprogressColor, "important");
    if (typeof(to.meta?.title) == 'string') {
      console.log("设置标题:" + to.meta?.title);
      document.title = to.meta?.title;
    }
    next();
});

// router.afterEach(() => {
//     NProgress.done(); // finish progress bar
// });

export default router;
