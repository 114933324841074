import { RouteRecordRaw } from "vue-router";
import DefaultLayout from "../layouts/default.vue";
// import HomeView from "../views/HomeView.vue";
// import EnterpriseServicesView from "../views/EnterpriseServicesView.vue";
// import HelpCenterView from "../views/HelpCenterView.vue";

export const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "defaultlayout",
        component: DefaultLayout,
        children: [
            {
                path: "/",
                name: "home",
                meta: {
                    title: '铭权科技'
                },
                component: () => import('@/views/HomeView.vue'),
            },
            {
                path: "/ipsintro",
                name: "ipsintro",
                meta: {
                    title: '知识产权'
                },
                component: () => import('@/views/IpsIntroView.vue'),
            },
            {
                path: "/enservices",
                name: "enservices",
                meta: {
                    title: '企业服务'
                },
                component: () => import('@/views/EnterpriseServicesView.vue'),
            },
            {
                path: "/helpcenter",
                name: "helpcenter",
                meta: {
                    title: '帮助中心'
                },
                component: () => import('@/views/HelpCenterView.vue'),
            },
            {
                path: "/about",
                name: "about",
                meta: {
                    title: '关于我们'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(/* webpackChunkName: "about" */ "@/views/AboutView.vue"),
            },
        ]
    },
];