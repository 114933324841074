import type { MenuItem } from '@/interface';

let menuList: Array<MenuItem> = [
    // {
    //     id: 1,
    //     title: '首页',
    //     path: '/',
    // },
    // {
    //     id: 2,
    //     title: '知识产权',
    //     path: '/ipsintro',
    // },
    // {
    //     id: 4,
    //     title: '企业服务',
    //     path: '/enservices',
    // },
    // {
    //     id: 5,
    //     title: '帮助中心',
    //     path: '/helpcenter',
    // },
    // {
    //     id: 6,
    //     title: '关于我们',
    //     path: '/about',
    // }
]

export default menuList;