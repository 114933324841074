import type { App } from 'vue';
import MQTreeMenu from './src/index.vue';
import MQMenuItem from './src/MenuItem.vue';
import MQSubMenu from './src/SubMenu.vue';
import MQReSubMenu from './src/ReSubMenu.vue';

const components = [
    MQTreeMenu,
    MQMenuItem,
    MQSubMenu,
    MQReSubMenu
]

// MQTreeMenu.install = (app: App): void => {
//     components.map(component => app.component(component.name, component))
//     //   app.component(MQTreeMenu.name, MQTreeMenu)
//     //   app.component(MQMenuItem.name, MQMenuItem)
//     //   app.component(MQSubMenu.name, MQSubMenu)
//     //   app.component(MQReSubMenu.name, MQReSubMenu)
// }

export {
    MQTreeMenu,
    MQMenuItem,
    MQSubMenu,
    MQReSubMenu
}

export default {
    MQTreeMenu,
    MQMenuItem,
    MQSubMenu,
    MQReSubMenu,
    install(app: App) {
        components.map(component => app.component(component.name, component))
        // app.component('MQTreeMenu', MQTreeMenu)
    },
}